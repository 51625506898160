<template>
  <div>
    <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="units"
      row-key="id"
    >
      <div slot="empty" v-if="loading">
        <img src="/img/loading.gif" style="height: 100px; width: 100px" />
      </div>
      <el-table-column :label="$t('COMMON.NAME')" prop="name">
        <template v-slot="{ row }">
          <base-input
            :placeholder="$t('COMMON.NAME')"
            v-model="unitsModel[row.id].name"
            input-classes="form-control-alternative"
            @change="updateUnitDebounced(row.id)"
          >
          </base-input>
        </template>
      </el-table-column>
      <el-table-column :label="$t('PRODUCTS.UNIT_TYPE')" prop="unit_type">
        <template v-slot="{ row }">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="unitsModel[row.id].unit_type"
              :placeholder="$t('PRODUCTS.UNIT_TYPE')"
              @change="updateUnitDebounced(row.id)"
            >
              <el-option
                class="select-primary"
                v-for="type in UNIT_TYPES"
                :key="type"
                :label="$t(`PRODUCTS.UNIT_TYPE_${type}`)"
                :value="type"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column :label="$t('PRODUCTS.UNIT_RATIO')" prop="ratio">
        <template v-slot="{ row }">
          <base-input
            :placeholder="$t('PRODUCTS.UNIT_RATIO')"
            v-model="unitsModel[row.id].ratio"
            input-classes="form-control-alternative"
            @change="updateUnitDebounced(row.id)"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="40px">
        <div slot-scope="{ row }" class="table-actions">
          <el-tooltip
            content="Loading"
            placement="top"
            v-if="unitsModel[row.id].loading"
          >
            <a
              type="text"
              class="table-action table-action-delete"
              data-toggle="tooltip"
            >
              <i class="fas fa-spinner fa-spin"></i>
            </a>
          </el-tooltip>

          <el-tooltip
            content="Delete"
            placement="top"
            v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
          >
            <a
              type="text"
              @click="deleteUnit(row)"
              class="table-action table-action-delete"
              data-toggle="tooltip"
              style="cursor: pointer"
            >
              <i class="fas fa-trash"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
    <div class="row px-3 mt-3">
      <base-button
        type="info"
        class="btn-sm mr-1 col-12"
        style="width: 100%"
        @click="
          () => {
            addLine();
          }
        "
      >
        <i class="fas fa-plus"></i>
        {{ $t("PRODUCTS.ADD_PRODUCT") }}
      </base-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  UNIT_TYPE_BIGGER_THAN_REFERENCE,
  UNIT_TYPES,
} from "@/constants/products";

export default {
  name: "unit-of-measure-view-global-units",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    unitOfMeasure: {
      type: Object,
      default: null,
      description: "Unit of measure",
    },
  },

  data() {
    return {
      loading: false,
      sort: "created_at",
      units: [],
      unitsModel: {},
      UNIT_TYPES: UNIT_TYPES,
    };
  },

  mounted() {
    this.getListDebounced();
  },

  computed: {},

  watch: {
    units: {
      handler: "refreshUnits",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    updateUnitDebounced: _.debounce(function (id) {
      this.updateUnit(id);
    }, 300),

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            unitOfMeasure: this.unitOfMeasure.id,
          },
          page: {
            number: 1,
            size: 999999,
          },
          include: "",
        };

        await this.$store.dispatch("unitOfMeasureUnits/list", params);
        this.units = await this.$store.getters["unitOfMeasureUnits/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteUnit(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("PRODUCTS.DELETE_THIS_WAREHOUSE_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
        try {
          await this.$store.dispatch("unitOfMeasureUnits/destroy", row.id);
        } catch (error) {
          await this.showRequestError(error);
        }
      }

      this.units = this.units.filter((item) => item.id != row.id);
    },

    addLine() {
      let newLinesIds = Object.values(this.units)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newUnit = {
        type: "unit-of-measure-units",
        relationshipNames: ["unitOfMeasure"],
        id: newLineId,
        name: null,
        unit_type: UNIT_TYPE_BIGGER_THAN_REFERENCE,
        ratio: null,
        unitOfMeasure: {
          type: "unit-of-measures",
          id: this.unitOfMeasure.id,
        },
      };
      this.units.push(_.cloneDeep(newUnit));
      this.unitsModel[newLineId] = _.cloneDeep(newUnit);
    },

    refreshUnits() {
      const unitsModelData = {};
      for (const item of this.units) {
        unitsModelData[item.id] = _.cloneDeep(item);
        unitsModelData[item.id].loading = false;
      }
      this.unitsModel = unitsModelData;
    },

    async updateUnit(id) {
      const unitData = this.unitsModel[id];

      if (!unitData.name || !unitData.unit_type || !unitData.ratio) {
        return;
      }

      this.unitsModel[id].loading = true;
      try {
        if (unitData.id > 0) {
          await this.$store.dispatch("unitOfMeasureUnits/update", unitData);
        } else {
          delete unitData.id;
          await this.$store.dispatch("unitOfMeasureUnits/add", unitData);
        }
      } catch (error) {
        await this.showRequestError(error);
      }
      this.getList();
    },
  },
};
</script>
